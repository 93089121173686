import Rails from '@rails/ujs'
import Turbolinks from 'turbolinks'
import 'jquery'


global.$ = require('jquery');

Rails.start()
Turbolinks.start()

const fn = function() {
  // your page initialization code here
  // the DOM will be available here

  // // Get the contact modal
  // const contact_modal = document.getElementsByClassName("modal")[0];

  // // Get the button that opens the modal
  // const btn = document.getElementById("modal-btn");

  // // Get the <span> element that closes the modal
  // const spans = document.getElementsByClassName("btn-close");

  // // Terms of service and privacy policy
  // const terms = document.getElementsByClassName("terms-of-service")[0];
  // const privacy = document.getElementsByClassName("privacy-policy")[0];

  // const terms_modal = document.getElementsByClassName("terms-modal")[0];
  // const privacy_modal = document.getElementsByClassName("privacy-modal")[0];

  // // When the user clicks the button, open the modal
  // btn.addEventListener('click', () => {
  //   contact_modal.style.display = "block";
  // })

  // // When the user clicks on <span> (x), close the modal
  // for (let span of spans) {
  //   span.addEventListener('click', () => {
  //     contact_modal.style.display = "none";
  //     terms_modal.style.display = "none";
  //     privacy_modal.style.display = "none";
  //   })
  // }

  // // When the user clicks on terms or privacy
  // terms.addEventListener('click', () => {
  //   terms_modal.style.display = "block";
  // })
  // privacy.addEventListener('click', () => {
  //   privacy_modal.style.display = "block";
  // })

  // // When the user clicks anywhere outside of the modal, close it
  // window.onclick = function(event) {
  //   if (event.target == contact_modal) {
  //     contact_modal.style.display = "none";
  //   } else if (event.target == terms_modal) {
  //     terms_modal.style.display = "none";
  //   } else if (event.target == privacy_modal) {
  //     privacy_modal.style.display = "none";
  //   }
  // }

  // document.addEventListener("ajax:success", (event) => {
  //   const [data, _status, _xhr] = event.detail;
  //   const defaultOptions = { positionClass: 'toast-top-center mt-5 pt-3', timeOut: "5000", closeButton: true }
  //   contact_modal.style.display = "none";
  //   return toastr.success(data, '', defaultOptions)
  // });

  // document.addEventListener("ajax:error", (event) => {
  //   const [data, _status, _xhr] = event.detail;
  //   const defaultOptions = { positionClass: 'toast-top-center mt-5 pt-3', timeOut: "5000", closeButton: true }
  //   return toastr.error([data].flat().join('<br/>'), '', defaultOptions)
  // });
};
require("../custom/custom");
require("../custom/jquery.fancybox");
document.addEventListener('turbolinks:load', fn, false);

document.addEventListener("turbolinks:load", function () {
    $('.js-fancybox').each(function() {
        $(this).fancybox();
    })

    $(".select-status-filter").change(
        function () {
            $.ajax({
                url: "/to_dos?status=" + $(".select-status-filter option:selected").val(),
                type: 'GET',
                dataType: 'script',
                success: function () {}
            })
        }
    );
    $("#notiDropdown").click( function () {
      $.ajax({
          url: "/notifications",
          type: 'GET',
          dataType: 'script',
          success: function () {
            $(this).removeClass('text-warning')
          }
      })
    });
});

$(document).on("click", ".status-change", function () {
    $.ajax({
        url: '/to_dos/' + $(this).attr('data-checked-id') + '/change_status',
        type: 'PUT',
        dataType: 'script',
        success: function () {}
    });
});

$(document).on('change','.no-known-date', (e) => {
    if(e.target.checked){
        $("#"+e.target.dataset.target ).attr('required', false)
    } else {
        $("#"+e.target.dataset.target ).attr('required', true)
    }
})

$(document).on("click", ".dfc_timeline", function () {
  $(".dfc_rec").show();
  $(".pt_rec, .lif_rec").hide();
  $(this).addClass('active');
  $(".search_timeline, .lif_timeline").removeClass('active');
});
$(document).on("click", ".lif_timeline", function () {
  $(".lif_rec").show();
  $(".pt_rec, .dfc_rec").hide();
  $(this).addClass('active');
  $(".search_timeline, .dfc_timeline").removeClass('active');
});
$(document).on("click", ".search_timeline", function () {
  $(".search_timeline, .dfc_timeline, .lif_timeline").removeClass('active');
  $(this).addClass('active');
  $(".pt_rec, .dfc_rec, .lif_rec").hide();
  if($(this).attr('value') == "All"){
    $(".pt_rec").show()
  } else {
    $(".pt_rec[trackable='"+$(this).attr('value')+"']").show()
  }
});
$(document).on("ajax:success", ".delete-timeline", function () {
  $(this).closest('.pt_rec').remove();
})
$(document).on('select2:open', (e) => {
    const selectId = e.target.id
    $(".select2-search__field[aria-controls='select2-" + selectId + "-results']").each(function (
        key,
        value,
    ){
        value.focus();
    })
})
function validateFiles(inputFile) {
    var maxExceededMessage = "This file exceeds the maximum allowed file size (10 MB)";
    var maxFileSize = $(inputFile).data('max-file-size');
    var sizeExceeded = false;
    $.each(inputFile.files, function() {
        if (this.size && maxFileSize && this.size > parseInt(maxFileSize)) {sizeExceeded=true;};
    });
    if (sizeExceeded) {
        window.alert(maxExceededMessage);
        $(inputFile).val('');
    };
}
window.validateFiles=validateFiles
function check_allergy_type(type, find){
  if($(".allergy-history-item").find("td:first:contains('"+find+"')").length > 0){
    $("#"+type).prop('checked', false);
    alert("You need to remove "+find+" from Allergies History to check this checkbox");
  } else {
    $.ajax({
      type: 'post',
      url: `/patients/${$('#allergy_patient_id').val()}/save_allergy_type`,
      data: {type: type, find: find, set_value: $('#' + type).is(":checked")},
      dataType: 'Script',
    })
  }
}
window.check_allergy_type=check_allergy_type
